@tailwind base;
@tailwind components;
@tailwind utilities;




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#EFEEE7;
  @apply w-full h-full;
  /* @apply bg-stone-100 dark:bg-gray-800; */
}

@font-face {
  font-family: 'NibPro-SemiBold';
  src: local('NibPro-SemiBold'), url(./assets/fonts/NibPro-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Moderat-Regular';
  src: local('Moderat-Regular'), url(./assets/fonts/Moderat-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Moderat-Bold-Italic';
  src: local('Moderat-Bold-Italic'), url(./assets/fonts/Moderat-Bold-Italic.ttf) format('ttf');
}

@font-face {
  font-family: 'Moderat-Regular-Italic';
  src: local('Moderat-Regular-Italic'), url(./assets/fonts/Moderat-Regular-Italic.ttf) format('ttf');
}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  @apply w-full h-full;
}


#root {
  height: 100%;
  width: 100%;
}

.aspect-auto {
  object-fit:cover;
}

.notion-header {
  display:  none !important;
}

.bubble_text_container h1{
  font-size: revert !important;
}

.bubble_text_container h2{
  font-size: revert !important;
}
#edit_reflection span,p{
  @apply !font-mod !text-base !text-left;
}
#submission_text span,p {
  @apply !font-mod !text-base !text-left;
}
.bubble_text_container span{
  @apply !font-mod !text-base;
}

.DraftEditor-editorContainer{
  @apply !max-h-52 !overflow-scroll;
}

.bubble_text_container a{
   @apply !text-gold font-mod font-bold underline;
   cursor: pointer !important;
}


.bubble_text_container>h2 {
  @apply !pt-2.5;
}

.bubble_text_container>ul {
  @apply !pt-2.5 !ml-[1.7rem];
}

#submission_text span {
  @apply !bg-transparent;
}

#my_bubble_text span, h1, h2 {
  @apply !bg-transparent !text-main-black !mt-1;
};

#other_bubble_text span, h1, h2{
  @apply !bg-transparent !text-main-black !mt-1;
}
/* #edit_reflection span, h1, h2{
  @apply !bg-transparent;
} */
/* #post_reply_submission span, h1, h2{
  @apply !bg-transparent;
} */

#list_chat_span_span p, span{
  @apply !bg-transparent;
}

#list_chat_span_span p>span{
  @apply !text-main-black;
}

.bubble_text_container h1 {
  /* !mt-1 */
  @apply !leading-[2.4rem]
}

.rdw-suggestion-dropdown {
  @apply !bg-white
}

.gOhHzB {
  display: none !important;
}
.rdw-link-decorator-wrapper span {
  @apply !text-gold !font-bold !underline;
  cursor: pointer !important;
}

.bubble_max_height_defined {
}
.bubble_max_height_defined::after {
}

/* 
.DraftEditor-editorContainer {

} */